if (document.querySelector('.referenzen__main')) {
    const filterButtons = document.querySelectorAll('.filter__button')
    filterButtons.forEach(btn => { 
        btn.addEventListener('click', e => { 
            const filteredItems = []
            const el = btn.dataset.filter
            const targets = document.querySelectorAll(`.referenzen__content-item`)
            targets.forEach(t => { 
                t.style.display = "none"
                t.classList.remove('even')
                t.classList.remove('uneven')
                if (el === 'all') {
                    filteredItems.push(t)
                } else {     
                    if (t.dataset.target.indexOf(el) > -1 || t.dataset.target.indexOf('optik&akustik') > -1 ) {
                        filteredItems.push(t)
                    } else { 
                        
                    }
                }
            })
            filterButtons.forEach(b => b.classList.remove('active'))
            btn.classList.add('active')

            filteredItems.forEach((item, idx) => {
                item.style.display = ""
                if(idx % 2 === 0){
                    item.classList.add('uneven')
                } else {
                    item.classList.add('even')
                }
            })

        })
    })
}