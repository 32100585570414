let slide = 0;
const slider = (el) => {
    const sliderContainer = document.getElementById(el)
    const slides = sliderContainer.querySelectorAll('img')
 
    for (let i = 0; i < slides.length; i++) { 
        slides.forEach(s => s.classList.remove(...s.classList))
        slides[slide - 1] ? slides[slide - 1].classList.add('prev') : slides[slides.length - 1].classList.add('prev')
        slides[slide].classList.add('active')
        slides[slide+1] ? slides[slide+1].classList.add('next') : slides[0].classList.add('next') 
    }
}

if (document.getElementById('desktop__slide')) { 
    const slides = document.querySelectorAll('#desktop__slide img')
    setInterval(() => {
        if (slide < slides.length - 1) {
            slide++
        } else { 
            slide = 0
        }
        slider('desktop__slide')
        slider('mobile__slide')
    }, 2500)
    // setInterval(()=>slider('mobile__slide'), 2500)
    // slider('desktop__slide')
}
