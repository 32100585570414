const openNav = () => { 
    const body = document.body
    body.classList.toggle('active')
}

const closeNav = () => { 
    document.body.classList.remove('active')
    //enableScroll()
}
const nav = document.querySelector('nav')

window.addEventListener('scroll', e => { 
    if (window.scrollY > 60) {
        nav.classList.add('scrolled')
    } else { 
        nav.classList.remove('scrolled')
    }
})


