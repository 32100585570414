if (document.getElementById('team')) { 
    const teamMembers = document.querySelectorAll('.mitarbeiter')
    const teamIcons = document.querySelectorAll('.icon')
    let index = 0;

    const showTeam = () => { 
        teamMembers.forEach(t => t.classList.remove('active'))
        teamMembers[index].classList.add('active')
        index < teamMembers.length - 1 ? index++ : index = 0
    }

    const showMitarbeiter = (el,target) => { 
        const t = document.querySelector(`[data-trigger="${target}"]`)
        teamMembers.forEach(t => t.classList.remove('active'))
        teamIcons.forEach(t => t.classList.remove('active'))
        t.classList.add('active')
        el.classList.add('active')
    }

    const nextEmployee = () => { 
        let index;
        teamIcons.forEach(t => {
            if (t.classList.contains('active')) { 
                t.classList.remove('active')
                let maIndex = parseInt(t.dataset.target);
                teamMembers[maIndex].classList.remove('active')
                index = parseInt(t.dataset.target) + 1;
            }
        })
        if (index < teamIcons.length) {
            document.querySelector(`[data-target="${index}"]`).classList.add('active')
            document.querySelector(`[data-trigger="${index}"]`).classList.add('active')
        } else { 
            teamIcons[0].classList.add('active')
            teamMembers[0].classList.add('active')
        }
    }
    const prevEmployee = () => { 
        let index;
        teamIcons.forEach(t => {
            if (t.classList.contains('active')) { 
                t.classList.remove('active')
                let maIndex = parseInt(t.dataset.target);
                teamMembers[maIndex].classList.remove('active')
                index = parseInt(t.dataset.target) - 1;
            }
        })
        if (index >= 0) {
            document.querySelector(`[data-target="${index}"]`).classList.add('active')
            document.querySelector(`[data-trigger="${index}"]`).classList.add('active')
        } else { 
            teamIcons[teamMembers.length - 1].classList.add('active')
            teamMembers[teamMembers.length - 1].classList.add('active')
        }
    }

    teamIcons.forEach(i => { 
        i.addEventListener('click', () => { 
            showMitarbeiter(i, i.dataset.target)
        })
    })

    // setInterval(showTeam, 5000)
}