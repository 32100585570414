//============================================
// Intersectionobserver
//============================================

const slidingElement = document.querySelectorAll("[data-observer]");

if (typeof IntersectionObserver === "undefined") {
    slidingElement.forEach(i => {
        i.classList.add('intersecting')
    });
} else {
  const slideInOptions = {
    threshold: 0,
    rootMargin: "0px 0px -100px 0px"
  };

  const slideInOnScroll = new IntersectionObserver(
    (entries, slideInOnScroll) => {
      entries.forEach(entry => {
          if (!entry.isIntersecting) {
            entry.target.classList.remove('intersecting')
            return;
        } else {
            entry.target.classList.add('intersecting')
          slideInOnScroll.unobserve(entry.target);
        }
      });
    },
    slideInOptions
  );
 

  slidingElement.forEach(image => {
    slideInOnScroll.observe(image);
  });
  
}

