const swiper = new Swiper("#intro__slider", {
    spaceBetween: 20,
    // centeredSlides: true,
    speed: 1000,
    initialSlide: 1,
    loop: true, 
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    breakpoints: {
        480: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        }
    },
});
