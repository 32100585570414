let counter = 0;
const imageToggle = el => { 
    const images = document.querySelectorAll(el)
    images.forEach(image => image.classList.remove('active'))
    images[counter].classList.add('active')
    counter < images.length - 1 ? counter++ : counter = 0
}

if (document.querySelector('.header__mitarbeiter')) {
    setInterval(() => imageToggle('.header__mitarbeiter'), 6000)
}