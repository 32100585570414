
let data = ""
let dataOT = ""

// $.getJSON('../../includes/data.json', json => {
//     data = json.leistungen
//     dataOT = json.tools
// })

let url = '../../includes/data.json';

fetch(url)
	.then(res => res.json())
	.then((out) => {
	data = out.leistungen
	dataOT = out.tools
})
.catch(err => { throw err });

// Modal Leistungen

const showModal = (el) => { 
    const modal = document.createElement('div')
    modal.id = 'modal'
    //modal.addEventListener('click', closeModal)
    
    let output = ""
    
    dataOT.forEach(d => { 
        
        if (d.id === el) output += `
            <div class="flex justifycenter tools">
                <span onclick='closeModal()'>&times;</span>
                <div class="col-1 flex aic justifycenter">
                    <img
                        src="assets/img/tools/desktop/${d.image}"
                        alt="${d.name}"></div>
                <div class="col-1">
                    <h3 class="color__darkgreen">${d.name}</h3>
                    <strong>${d.title}</strong>
                    <p>${d.beschreibung}<br><br>
                    ${ d.url !== '' ? '<a class="testlink" href="'+ d.url +'" target="_blank">Testlink </a> (grundsätzliche Funktion, Beispiel-Layout)' : ''}
                    </p> 
                    ${d.video ? `<a class="main__button dark" href="${d.video}" target="_blank" rel="noopener noreferrer">${d.video_button ? d.video_button : 'Video ansehen'}</a>` : ''}
                </div>
                <div class="col-1">
                    <a href="mailto:2do@2do-digital.de?subject=Anfrage%20${d.name}" class="main__button">Jetzt Tool anfragen</a>
                </div>
                `
                
    })

    data.forEach(d => { 
        if (d.name === el) output += `
            <div class="flex justifycenter">
                <span onclick='closeModal()'>&times;</span>
                <div class="gradient col-1 flex aic justifycenter">
                    <img
                        src="assets/img/mitarbeiter/${d.visual}.png"
                        srcset="assets/img/mitarbeiter/${d.visual}.png 1x, assets/img/mitarbeiter/${d.visual}@2x.png 2x" 
                        alt="${d.visual.split('.')[0]}"></div>
                <div class="col-1">
                    <em>${d.info}</em>
                </div>`
    })

   

    output += "</div>"
    modal.innerHTML = output
    document.body.appendChild(modal)
}




const closeModal = () => { 
    document.body.removeChild(document.getElementById('modal'))
    // document.getElementById('leistungen').classList.remove('modal_open')
}

window.addEventListener('keyup', e => e.key === 'Escape' && closeModal())


